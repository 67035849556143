import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
// import CustomMap from "ps-components/Maps/CustomMap";
import classNames from "classnames";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// var apiBaseUrl = process.env.API_URL;

const HomecareTreatments = ({ classes, title,summary }) => {
  return (
    <GridContainer >
      <GridItem md={10} sm={10} className={classNames(classes.mrAuto, classes.mlAuto)}>
        <h2 className={classNames(classes.title, classes.textCenter)}>{title}</h2>
        <h5>{summary}</h5>
      </GridItem>
      <Paper className={classes.root} style={{marginLeft: "3.5%"}} >
                    <Table className={classes.table} fixedHeader={false} style={{tableLayout: "auto" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    rowSpan={1}
                                    colSpan={7}
                                    className={classes.tableCellwidth + ` ` + classes.headCell}
                                    style={{width: `50px`}}
                                >
                                    Homecare Service Provider Name
                               </TableCell>
                            <TableCell
                            colSpan={7}
                                align="center"
                                className={classes.tableCellwidth + ` ` + classes.headCell}
                            >
                                Toll Free Number
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key='waiting_periods'>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth}>
                                CallHealth
                                </TableCell>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth}>
                                044 - 6900 6900
                                </TableCell>
                            </TableRow>
                            <TableRow key='waiting_periods'>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth1}>
                                Portea Home Healthcare Service
                                </TableCell>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth1}>
                                044 - 6900 6900
                                </TableCell>
                            </TableRow>
                            <TableRow key='waiting_periods'>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth}>
                                Care 24
                                </TableCell>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth}>
                                044 - 6900 6900
                                </TableCell>
                            </TableRow>
                            <TableRow key='waiting_periods'>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth1}>
                                Athulya Assisted Living Pvt Ltd
                                </TableCell>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth1}>
                                044 - 6900 6900
                                </TableCell>
                            </TableRow>
                            <TableRow key='waiting_periods'>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth}>
                                India Home Health Care Pvt Ltd
                                </TableCell>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth}>
                                044 - 6900 6900
                                </TableCell>
                            </TableRow>
                            <TableRow key='waiting_periods'>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth1}>
                                One Life Home Health Care
                                </TableCell>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth1}>
                                044 - 6900 6900
                                </TableCell>
                            </TableRow>
                            <TableRow key='waiting_periods'>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth}>
                                Argala Health Services Pvt Ltd, Hyderabad
                                </TableCell>
                                <TableCell align="center" colSpan={7} className={classes.tableCellLeftcustomWidth}>
                                044 - 6900 6900
                                </TableCell>
                            </TableRow>
                    </TableBody>
                </Table>
                </Paper>
    </GridContainer>
  );
};
HomecareTreatments.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  summary: PropTypes.string
};
export default withStyles(mediaCenterStyle)(HomecareTreatments);
