import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.jsx";
// import classNames from "classnames";

const NHNetworkHospitals = ({ classes }) => {
  // const { classes } = this.props
  return (
    <GridContainer>
      <GridItem>
          <Button
          href="/Preventive-Health-Check-walkin"
          className={classes.preventivecses}
          style={{backgroundColor: '#35488a'}}
          >
              Preventive Health Check services – WALK IN
          </Button>
          <Button 
          href="/Preventive-Health-Check-doorstep"
          style={{backgroundColor: '#35488a', marginLeft: '1%'}}>
            
              Preventive Health Check services – DOOR STEP
          </Button>
          <Button 
          href="/Preventive-Health-Check-reimbursement"
          style={{backgroundColor: '#35488a', marginLeft: '1%'}}>
              Preventive Health Check services – REIMBURSEMENT
          </Button>
      </GridItem>
    </GridContainer>
  );
};
NHNetworkHospitals.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.object,
};
export default withStyles(mediaCenterStyle)(NHNetworkHospitals);
