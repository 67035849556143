import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import CustomMap from "ps-components/Maps/CustomMap";
import classNames from "classnames";
import PropTypes from "prop-types";
var apiBaseUrl = process.env.API_URL;

const NHNetworkHospitals = ({ classes, title,summary }) => {
  return (
    <GridContainer>
      <GridItem
        md={10}
        sm={10}
        className={classNames(classes.mrAuto, classes.mlAuto)}>
           <h2 className={classNames(classes.title, classes.textCenter)}>
          {title}
        </h2>
        <h5>{summary}</h5>
        <br/>
        <h5>Star Health Insurance is delighted to be associated with you and we value your services to our customers. We have a dedicated online Portal for submission of claims , processing and payment for our Network Hospitals. We also have a 24 *7 call centre for help or clarifications on any matters connected with your claims . Therefore, please do not engage any third party to collect and submit the Claim documents on your behalf.
          Kindly utilize the above mentioned services to submit your claim with us.
          Let us work together in Harmony”</h5>
      </GridItem>
      <GridItem md={12}>
        <CustomMap
          id="network"
          searchMessage="Search Network Hospitals"
          category="network"
          icon_url={`https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png`}
          icon_size={25}
          url={`${apiBaseUrl}hospital/search`}
          cityUrl={`${apiBaseUrl}hospital/city`}
          defaultView={`list`}
          helperText={`network hospitals`}
          disableListView={process.env.DISABLE_LISTVIEW === `true` ? true : false}
          showTypeInListView
        />
      </GridItem>
    </GridContainer>
  );
};
NHNetworkHospitals.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  summary: PropTypes.string,
};
export default withStyles(mediaCenterStyle)(NHNetworkHospitals);
