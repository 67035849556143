import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import CustomMap from "ps-components/Maps/CustomMap";
import classNames from "classnames";
import PropTypes from "prop-types";
var apiBaseUrl = process.env.API_URL;

const NHNetworkHospitals = ({ classes, title }) => {
  return (
    <GridContainer>
      <GridItem
        md={10}
        sm={10}
        className={classNames(classes.mrAuto, classes.mlAuto)}>
        <h2 className={classNames(classes.title, classes.textCenter)}>
          {title}
        </h2>
      </GridItem>
      <GridItem md={12}>
        <CustomMap
          id="labs"
          searchMessage="Search Network Labs"
          category="labs"
          icon_url={`https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png`}
          icon_size={25}
          url={`${apiBaseUrl}hospital/search`}
          cityUrl={`${apiBaseUrl}hospital/city`}
          defaultView={`list`}
          disableListView={process.env.DISABLE_LISTVIEW === `true` ? true : false}
          helperText={`network labs`}
        />
      </GridItem>
    </GridContainer>
  );
};
NHNetworkHospitals.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
};
export default withStyles(mediaCenterStyle)(NHNetworkHospitals);
