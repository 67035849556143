import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import mediaCenterStyle from "ps-assets/jss/mediaCenterStyle.jsx";
import CustomMap from "ps-components/Maps/CustomMap";
import classNames from "classnames";
import PropTypes from "prop-types";
var apiBaseUrl = process.env.API_URL;

const NetworkAyush = ({ classes, title,summary }) => {
  return (
    <GridContainer >
      <GridItem md={10} sm={10} className={classNames(classes.mrAuto, classes.mlAuto)}>
        <h2 className={classNames(classes.title, classes.textCenter)}>{title}</h2>
        <h5>{summary}</h5>
      </GridItem>
      <GridItem md={12} style={{width:'100%'}}>
        <CustomMap
          id="AyushHospitals"
          searchMessage="Search Network Providers for Ayush Hospitals"
          category="ayush"
          icon_url={`https://maps.gstatic.com/mapfiles/place_api/icons/doctor-71.png`}
          icon_size={25}
          url={`${apiBaseUrl}hospital/search`}
          cityUrl={`${apiBaseUrl}hospital/city`}
          defaultView={`list`}
          pageNo={1}
          totalRecord={30}
          helperText={`Ayush Hospitals`}
          tableView={true}
          disableListView={process.env.DISABLE_LISTVIEW === `true` ? true : false}
        />
      </GridItem>
    </GridContainer>
  );
};
NetworkAyush.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  summary: PropTypes.string
};
export default withStyles(mediaCenterStyle)(NetworkAyush);
