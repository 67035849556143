import React from "react";
import { graphql } from "gatsby";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import NHAgreedNetworkHospitals from "./NHAgreedNetworkHospitals";
import NHNetworkLabs from "./NHNetworkLabs";
import NHNetworkHospitals from "./NHNetworkHospitals.jsx";
// import NHDiagnosticCenters from "./NHDiagnosticCenters";
import NHPreferredHospital from "./NHPreferredHospital";
import NPHPreferredHospital from "./NPHPreferredHospital";
import NetworkProvidersRehab from "./NetworkProvidersRehab";
import NetworkProvidersPainManage from "./NetworkProvidersPainManage";
import NHPreventivecases from "./NHPreventivecases";
// import HomecareTreatments from "./HomecareTreatments";
import Homecare from "./Homecare";
import PHCWomen from "./PHCWomen";
import NetworkAyush from "./NetworkAyush";
import { Helmet } from "react-helmet";
// import Search from "./Search.jsx";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
const searchRegExp = /<networkHospitalCount \/>/g
const stringSearchRegExp = /<stringNetworkHospitalCount \/>/g
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;
const STRHOSPITALCOUNT = process.env.STRHOSPITALCOUNT;

// var apiUrl = `${process.env.API_URL}`;

// const HEALTH_CHECKUP_PACKAGES = `health-checkup-packages`;

// const createList = function(res, setTotalCount) {
//   var responseList = res && (res.responseList ? res.responseList : []);
//   var responseArr = [];
//   responseList &&
//     responseList.map(response => {
//       var temp = [];
//       var res_temp = {};
//       switch (response.service_provider.toLowerCase()) {
//         // case `aiwo`:
//         //   res_temp = {
//         //     name: `AIWO Health Center`,
//         //     url: `/${HEALTH_CHECKUP_PACKAGES}-aiwo`
//         //   };
//         //   break;
//         case `safeguard`:
//           res_temp = {
//             name: `Safeguard Family Health Private Limited`,
//             url: `/${HEALTH_CHECKUP_PACKAGES}-safeguard-family-health-private-limited`
//           };
//           break;
//         case `i2h`:
//           res_temp = {
//             name: `I2H`,
//             url: `/${HEALTH_CHECKUP_PACKAGES}-i2h`
//           };
//           break;
//         // case `1mg`:
//         //   res_temp = {
//         //     name: `1MG`,
//         //     url: `/${HEALTH_CHECKUP_PACKAGES}-1mg`
//         //   };
//         //   break;
//         case `neuberg`:
//           res_temp = {
//             name: `Neuberg Diagnostic Private Limited`,
//             url: `/${HEALTH_CHECKUP_PACKAGES}-neuberg`
//           };
//           break;
//       }
//       temp.push(res_temp.name);
//       temp.push(response.city);
//       temp.push(response.state);
//       temp.push(response.pinCode);
//       temp.push(
//         <a target="_blank" rel="noopener noreferrer" href={res_temp.url}>
//           View Package
//         </a>
//       );

//       responseArr.push(temp);
//     });
//   return {
//     responseArr: responseArr,
//     responseList: responseList,
//     setTotalCount: setTotalCount
//   };
// };

const MediaCenter = ({ classes, data, location }) => {
  // const searchOptions = {
  //   sort: false,
  //   serverSide: true
  // };
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];
  var navPillArr = []
  data.allStrapiSections.edges.map(document => {
    var obj = {};
    let content;
    switch (document.node.strapiId) {
      case 280:
        content = <NHNetworkHospitals title={document.node.title} summary={(document.node.summary).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}/>;
        break;
      case 281:
        content = <NHAgreedNetworkHospitals title={document.node.title} summary={(document.node.summary).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}/>;
        break;
      case 282:
        content = <NHNetworkLabs title={document.node.title} />;
        break;
      // case 283:
      //   content = <NHDiagnosticCenters title={document.node.title} />;
      //   break;
      case 283:
        content = <NHPreventivecases title={document.node.title} />;
        break;
      case 307:
        content = <NHPreferredHospital title={document.node.title}  summary={(document.node.summary).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)}/>;
        break;
      case 335:
        content = <NPHPreferredHospital title={document.node.title} summary={(document.node.summary).replace(searchRegExp, HOSPITALCOUNT).replace(stringSearchRegExp, STRHOSPITALCOUNT)} />;
        break;
      case 697:
          content = <NetworkProvidersRehab title={document.node.title} summary={document.node.summary} />;
          break;
      case 698:
        content = <NetworkProvidersPainManage title={document.node.title} summary={document.node.summary} />;
        break;
      case 764:
        content = <PHCWomen title={document.node.title} />;
        break;
      case 765:
        content = <Homecare title={document.node.title} summary={document.node.summary} />;
        break;
      case 978:
        content = <NetworkAyush title={document.node.title} summary={document.node.summary} />;
        break;
      // case 338:
      //   content = (
      //     <Search
      //       createList={createList}
      //       searchFor="preventive health checkup"
      //       title={document.node.title}
      //       summary={document.node.summary}
      //       apiUrl={`${apiUrl}checkup/centre`}
      //       noResultText="We do not have home pick up service at the requested location, kindly visit our diagnostic centre list"
      //       tableHeaders={[
      //         `Service Provider Name`,
      //         `Area/City`,
      //         `State`,
      //         `Pincode`,
      //         `Packages`
      //       ]}
      //       buttonData={{
      //         placeholder: `Search Pincode`,
      //         cta: `SEARCH HOME PICKUP CENTRES`
      //       }}
      //       searchOptions={searchOptions}
      //     />
      //   );
      //   break;
    }
    if(content){
    if(document.node.strapiId==700 
      // || document.node.strapiId==701 
      ){
        obj.tabButton=document.node.title.replace(/Network Providers for/g, '').replace(/&/g,"/").replace(/Treatment/g,'CARE')+' PROVIDER'
      }else{
      obj.tabButton = document.node.title;
      }
    obj.tabImage = document.node.icon.publicURL;
    obj.tabContent = content;
    navPillArr.push(obj);
  }
  });

  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title>
          Search for Star Health Insurance Network Hospitals in Your City
        </title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href="https://www.starhealth.in/network-hospitals"
          />
          <meta
            name="title"
            content="Star Health Insurance Network Hospitals list Near you"
          />
          <meta
            name="twitter:title"
            content="Search for Star Health Insurance Network Hospitals in Your City"
          />
          <meta
            name="description"
            content="Search for the best hospitals from our network hospitals list to avail cashless treatment with our Health Insurance Plans, on top of this get your treatments done at the best hospital in your city"
          />
          <meta property="og:title" content="Search for Star Health Insurance Network Hospitals in Your City" />
          <meta property="og:description" content="Search for the best hospitals from our hospitals network to avail cashless treatment with our Health Insurance Plans, on top of this get your treatments done at the best hospital in your city." />
          <meta property="og:url" content="https://www.starhealth.in/network-hospitals"/>
          <meta name="Search for Star Health Insurance Network Hospitals in Your City" />
          <meta name="twitter:description" content="Search for the best hospitals from our hospitals network to avail cashless treatment with our Health Insurance Plans, on top of this get your treatments done at the best hospital in your city." />
          <meta property="twitter:url" content="https://www.starhealth.in/network-hospitals" />
        </Helmet>
      )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem style={{ padding: `70px 0px` }}>
                <NavPills
                  color="primary"
                  location={location}
                  tabs={navPillArr}
                  alignCenter
                  variant={true}
                  largeNavPill={true}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
MediaCenter.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  location: PropTypes.object
};
export default withStyles(customStaticPageStyle)(MediaCenter);

export const mediaCenterQuery = graphql`
  query network {
    allStrapiSections(
      filter: { category: { eq: "network_hospital" } }
      sort: { fields: sequence, order: ASC }
    ) {
      edges {
        node {
          title
          summary
          strapiId
          icon {
            publicURL
          }
        }
      }
    }
    allStrapiImages(filter: { section: { category: { eq: "locate_us" } } }) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
